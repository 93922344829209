import { apiJobDetail } from '@/api/api.js'
import AudioTool from '@/components/AudioTool/AudioTool.vue'
export default {
  components: {
    AudioTool
  },
  data() {
    return {
      jobId: sessionStorage.getItem('tcl_jobId'),
      resultData: {},
      page: 1,
      startTime: '',
      endTime: '',
      dialogVisible: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    jump(data) {
      if (data.classify) {
        sessionStorage.setItem('type', data.classify.name)
        sessionStorage.setItem('claid', this.resultData.job_detail.id)
        sessionStorage.setItem('clid', data.clid)
        sessionStorage.setItem('claid_status', 1)
        // this.$router.push({ name: 'GrePracticeDetail', query: { clid: data.clid, type: data.classify.name, id: data.id } })
        this.$router.push({
          name: 'Exercises',
          query: {
            clid: data.clid,
            practiceId: data.id,
            claid: this.resultData.job_detail.id
          }
        })
      } else {
        sessionStorage.setItem('practice_id', data.id)
        sessionStorage.setItem('caqid', data.caqid)
        this.$router.push({ name: 'SkillExerciseTask' })
      }
    },
    init() {
      this.getJobDetail()
    },
    getJobDetail() {
      let paramsBase = {
        page: 1,
        page_size: 999999,
        job_id: this.jobId
      }
      apiJobDetail(paramsBase).then((res) => {
        this.resultData = res.data
        console.log(this.resultData)
        if (this.resultData.all_asses.status == 1) {
          let str = ''
          for (var i = 0; i < this.resultData.all_asses.title.length; i++) {
            if (i == this.resultData.all_asses.title.length - 1) {
              str += this.resultData.all_asses.title[i]
            } else {
              str += this.resultData.all_asses.title[i] + ','
            }
          }
          this.resultData.all_asses.title = str
        }
      })
    },
    clickSeeDetails() {},
    load() {
      this.page++
      // this.getJobDetail()
    },
    getAllasses() {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
